import { Component } from "react";
import { Link } from "react-router-dom";
import './NotFound.css'
class NotFound extends Component {
    render() {
        return (
            <div className="not_found__wrapper">
                <span className="text-large not_found">404 . Not Found</span>
                <Link to="/"><span className="text not_found"> Return to Home </span></Link>
            </div>
        )
    }
}

export default NotFound