import axios from "axios"

const People = (id, name, description, image, position) => {
    return {
        id: id,
        name: name,
        description: description,
        image: image,
        position: position,
    }
}

const EmptyPeople = () => {
    return People("","","","","")
}

const apiRoot = process.env.REACT_APP_API_ROOT

const GetPeople = () => {
    return axios.get(`${apiRoot}/people`)
}

const exported = {
    People,
    EmptyPeople,
    GetPeople
}

export default exported