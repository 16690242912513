import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import ClientItem from "./ClientItem";

const styles = (theme) => makeStyles({
    clientWrapper: {
        backgroundColor: theme.palette.surface.active,
        color: theme.palette.on.surface.active,
        padding: "36px 5%",
        [theme.breakpoints.up('sm')]: {
            padding: "64px 10%",
        },
    },
    titleWrapper: {
        marginBottom: "30px",
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            fontSize: "48px",
            marginBottom: "64px",
        },
    },
    contentWrapper: {
    },
    clientItemWrapper: {
        paddingRight: "64px",
    }
})
const createClientData = (category, items) => {
    return {
        category: category,
        items: items
    }
}
const clientData = [
    createClientData("Consumer Goods", 
        ["Ferrero", "Nutella","Kraft Heinz", "Unilever", "Diageo", "Heineken"]
    ),
    createClientData("Technology", [
        "Google","Spotify","Gojek", "Traveloka", "Bukalapak"
    ]),
    createClientData("Financial Services", ["BTPN", "DBS", "Bukukas"]),
    createClientData("Consumer Health", ["Herbana", "Combiphar"]),
    createClientData("Non-profit", ["Tubaba", "Unicef"])
]
export default function Client() {
    const theme = useTheme()
    const classes = styles(theme)()
    return (
        <div className={classes.clientWrapper}>
            <div className={`${classes.titleWrapper} text-large`}>
                Client past and present
            </div>
            <div className={classes.contentWrapper}>
                <Grid container spacing={6}>
                    {clientData.map(function(d, i) {
                        return (
                            <Grid item xs={4}>
                                <ClientItem 
                                    category={d.category}
                                    items = {d.items}
                                    key = {i}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                
            </div>
        </div>
    )
}