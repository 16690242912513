import React from 'react';
import './Carousel.css'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useTheme, useMediaQuery } from '@material-ui/core';

export default function Carousel(props) {
    const content = props.content
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('sm'))
    if (content.length === 0) {
        return (<div></div>)
    }
    const firstContent = props.content[0]
    const otherContent = props.content.slice(1, props.content.length)
    const onSlideMoved = (_, newIndex) => {
        // https://splidejs.com/events/
        if (props.onSlideMoved) {
            props.onSlideMoved(newIndex)
        }
    }
    const autoplayInterval = props.autoplayInterval? props.autoplayInterval: 1500
    return(
        <div>
            <Splide options={{
                autoplay: true,
                rewind: true,
                pauseOnHover: true,
                pagination: false,
                interval: autoplayInterval,
                arrows: desktop
            }}
            onMoved = {onSlideMoved}
                className={desktop?"carousel_custom_arrow": "carousel_hidden_arrow"}
            >
                <SplideSlide> 
                    <div className="carousel-slide__wrapper">
                        <div className="image_div_contain carousel-slide" style={{ backgroundImage: `url(${firstContent.file})` }}/>
                    </div>
                </SplideSlide>
                {otherContent.map(i => 
                    <SplideSlide>
                        <div className="carousel-slide__wrapper">
                            <div 
                                className="carousel-slide image_div_contain"
                                style={{ backgroundImage: `url(${i.file})` }}
                                key={i.priority}
                            />
                        </div>
                    </SplideSlide>
                )}
            </Splide>
        </div>
    )
}