import { Fade, makeStyles, useTheme } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React from "react"
import peopleStore from '../../../store/people'

// https://material-ui.com/styles/basics/#adapting-based-on-props
// I'm not sure if we can combine passing props with theme
// if we have the requirement to do so, better create seperate styles (another makeStyles)
const styles = makeStyles({
    overlayWrapper: props => ({
        width: '100%',
        height: '100%',
        position: "absolute",
        zIndex: "10000",
        left: "0",
        top: props.top,
    }),
    overlayBackground: {
        backgroundColor: "#000000",
        width: "100%",
        height: "100vh",
        opacity: "0.5",
        position: 'absolute',
    },
    overlayClickContainer: {
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "zoom-out"
    },
})

const cardStyles = (theme) => {
    return makeStyles({
        wrapper: {
            height: "510px",
            width: "90vw",
            top: "20vh",
            cursor: "default",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        teamImage: {
            height: "320px",
            backgroundPosition: "top",
        },
        teamPosition: {
            fontSize: "13px",
            color: theme.palette.on.surface.main3,
            marginBottom: "16px",
        },
        teamDescription: {
            lineHeight: "24px",
            height: "18em",
            fontSize: "13px",
            color: theme.palette.on.surface.main2,
            overflow: "scroll",
        },
        teamName: {
            fontWeight: "bold",
            maxHeight: "3em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginBottom: "5px",
            fontSize: "16px",
        },
        teamText: {
            fontSize: "13px",
            padding: "14px 13px",
            overflow: "hidden",
            border: `1px solid ${theme.palette.primary.shadow}`,
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.surface.main,
        },
        closeCaption: {
            color: theme.palette.on.surface.main2,
            marginBottom: "20px",
            cursor: "pointer",
            position: "absolute",
            top: "16vh",
            textAlign: "right",
            float: "right",
            right: "7vw",
            fontSize: "32px",
        },
    })
}

export default function OverlayTeamCard(props) {
    const onBackgroundClick = (event) => {
        if (props.onBackgroundClick) {
            props.onBackgroundClick(event)
        }
    }
    const theme = useTheme()
    const classes = styles(props)
    const cardClasses = cardStyles(theme)()
    React.useEffect(() => {
        if (props.shown) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [props.shown]);

    const people = props.people ? props.people : peopleStore.EmptyPeople()
    return (
        <Fade in={props.shown}>
            <div className={classes.overlayWrapper}>
                <div className={classes.overlayBackground} />
                <div
                    onClick={(e) => onBackgroundClick(e)}
                    className={classes.overlayClickContainer}>
                    <div>
                        <div className={`${cardClasses.closeCaption} text-large`}>
                            <CloseIcon fontSize='inherit' />
                        </div>
                        <div className={cardClasses.wrapper}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className={`image_div ${cardClasses.teamImage}`}
                                style={{ backgroundImage: `url(${people.image})` }} />
                            <div className={cardClasses.teamText}>
                                <div className={`text ${cardClasses.teamPosition}`}>
                                    {people.position}
                                </div>
                                <div className={`text-large ${cardClasses.teamName}`}>
                                    {people.name}
                                </div>
                                <div className={`text ${cardClasses.teamDescription}`}>
                                    {people.description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    )
}