import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

const styles = (theme, backgroundColor, color) => {
    return makeStyles({
        wrapper: {
            [theme.breakpoints.up('sm')]: {
                padding: '68px 10vw 40px 10vw',
            },
            padding: '24px 5vw 18px 5vw',
            color: color,
            backgroundColor: backgroundColor,
            fontFamily:"franklin-gothic-atf",
        },
        office: {
            display: 'inline-block',
            paddingBottom: '10px'
        },
        jakartaOfficeWrapper: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: "2vw",
            },
            marginLeft: "0vw",
        },
        singaporeOfficeWrapper: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: "-2vw",
            },
            marginLeft: "0vw",
        },
        officeListWrapper: {
        },
        officeName: {
            color: theme.palette.on.surface.main,
            marginBottom: "12px",
            fontWeight: "bold",
        },
        officeLocation: {
            color: theme.palette.on.surface.main2,
        },
        socialMediaCaption: {
            fontWeight: "bold",
            marginBottom: "12px",
            color: theme.palette.on.surface.main,
        },
        socialMediaWrapper: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: "2vw",
            },
            marginLeft: "0vw",
        },
        socialMedia: {
            marginBottom: "9px",
            "& a": {
                textDecoration: "none",
                color: theme.palette.on.surface.main2,
            },
            "&:hover a": {
                textDecoration: "none",
                color: theme.palette.on.surface.contrastText,
            },
            [theme.breakpoints.down('xs')]: {
                "&.instagram": {
                    textAlign: "left",
                },
                "&.linkedin": {
                    textAlign: "center",
                },
                "&.medium": {
                    textAlign: "right",
                },
            }
        },
        contactCaption: {
            color: theme.palette.on.surface.main,
            marginBottom: "12px",
            fontWeight: "bold",
        },
        contact: {
            textDecoration: "none",
            color: theme.palette.on.surface.main2,
            marginBottom: "12px",
            "&:hover a": {
                textDecoration: "none",
                color: theme.palette.on.surface.contrastText,
            },
            "& a": {
                textDecoration: "none",
                color: theme.palette.on.surface.main2,
                overflowWrap: "break-word",
            },
        },
        contactWrapper: {
            [theme.breakpoints.up('xs')]: {
                paddingBottom: '30px',
            },
            paddingBottom: '10px',
        },
        copyright: {
            color: theme.palette.on.surface.main2,
        },
        contentContainer: {
            marginRight: "50px",
            display: "inline-block",
        }
    })()
}

export default function Footer(props) {
    const theme = useTheme()
    const classes = styles(theme, props.backgroundColor, props.color)
    
    return (
        <div>
            <div className={classes.wrapper}>
                <Grid container spacing={3} justify='space-between'>
                    <Grid item sm={3} xs={12}>
                        <div>
                            <div className={`text ${classes.contactCaption}`}>
                                Get in touch 
                            </div>
                            <div>
                                <div className={`text-small ${classes.contact}`}>
                                    <a href="mailto:info@nationinsights.com">info@nationinsights.com</a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <div className={classes.jakartaOfficeWrapper}>
                            <div className={`text ${classes.office}`}>
                                <div className={classes.officeName}>
                                    Jakarta
                                </div>
                                <div className={`${classes.officeLocation} text-small`}>
                                    Jl. Ciragil I No. 9 <br/>
                                    Jakarta Selatan 12180
                                </div>
                            </div> 
                        </div>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <div className={classes.singaporeOfficeWrapper}>
                            <div className={`text ${classes.office}`}>
                                <div className={classes.officeName}>
                                    Singapore
                                </div>
                                <div className={`${classes.officeLocation} text-small`}>
                                    Hong Leong Building<br/>
                                    16 Raffles Quay<br/>
                                    Singapore 048581<br/>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Grid item sm={2} xs={12}>
                        <div className={classes.socialMediaWrapper}>
                            <Grid container justify='space-between'>
                                <Grid item sm={12} xs={12}>
                                    <div className={`text ${classes.socialMediaCaption}`}>
                                        Find us
                                    </div>
                                </Grid>
                                <Grid item sm={12} xs={4} className={`text-small ${classes.socialMedia} instagram`}>
                                    <a href="https://www.instagram.com/nationinsights/" target="_blank" rel="external noopener">Instagram </a>
                                </Grid>
                                <Grid item sm={12} xs={4} className={`text-small ${classes.socialMedia} linkedin`}>
                                    <a href="https://www.linkedin.com/company/nation-insights/" target="_blank" rel="external noopener"> Linkedin</a>
                                </Grid>
                                <Grid item sm={12} xs={4} className={`text-small ${classes.socialMedia} medium`}>
                                    <a href="https://medium.com/@nationinsights" target="_blank" rel="external noopener">Medium </a>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item sm={12}>
                        <div className={`text-small ${classes.copyright}`}>
                            Nation Insights &copy; 2021
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}