import { makeStyles } from "@material-ui/core"

export default function HeaderStyles (theme) {
    return makeStyles({
        wrapper: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.on.primary.main,
            padding: '0px',
            margin: '0px',
            fontSize: '28px',
            display: 'block',
            position: 'fixed',
            width: '100%'
        },
        header: {
            width: 'auto',
            display: 'flex',
            margin: '0px',
            padding: '48px 5vw 16px 5vw',
            '& li': {
                listStyleType: 'none',  
            },
            [theme.breakpoints.up('sm')]: {
                padding: '36px 10vw 36px 10vw',
            },
        },
        headerMenu: {
            fontSize: '28px',
            [theme.breakpoints.up('md')]: {
                marginRight: '5vw',
            },
            marginRight: '3vw',
            '& a': {
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                    color: theme.palette.secondary.main
                }
            },
            '&.selected': {
                color: theme.palette.secondary.main
            },
            '&.logo': {
                marginRight: "5vw",
            },
            [theme.breakpoints.down('lg')]: {
                '&.logo': {
                    marginRight: "8vw",
                },
            },
        },
        headerMenuXs: {
            fontSize: '48px',
            '& a': {
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                    color: theme.palette.on.secondary.contrastText
                }
            },
            '&.selected': {
                color: theme.palette.on.secondary.contrastText
            },
            marginBottom: "6px",
        },
        vermillionWrapper: {
            position: 'absolute',
            float: 'right',
            top: '52px',
            [theme.breakpoints.down('md')]: {
                right: '5vw'
            },
            right: '9vw',
        }
    })()
}