import { useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core"
import peopleStore from '../../../store/people'
import React from 'react';
import OverlayTeamCard from "../../view/team/OverlayTeamCard";

const styles = (theme) => {
    return makeStyles({
        wrapper: {
            justifyContent: "flex-start",
            lineHeight: "1.5em",
            height: "33em",
            fontSize: "12px",
            [theme.breakpoints.up('sm')]: {
                lineHeight: "1.5em",
                height: "66em",
                fontSize: "12px",
            },
            border: `1px solid ${theme.palette.primary.shadow}`,
            overflow: "hidden",
        },
        teamImage: {
            height: "177px",
            backgroundPosition: "top",
            [theme.breakpoints.up('sm')]: {
                height: "347px",
            }
        },
        teamPosition: {
            color: theme.palette.on.surface.main3,
            fontSize: "16px",
            marginBottom: "9px",
        },
        teamName: {
            fontWeight: "bold",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingBottom: "12px",
            lineHeight: "1.05em",
            [theme.breakpoints.up('sm')]: {
                paddingBottom: "9px",
                maxHeight: "2.7em",
            },
        },
        teamDescription: {
            [theme.breakpoints.up('sm')]: {
                lineHeight: "1.75",
                height: "21em",
                fontSize: "16px",
            },
            lineHeight: "1.5em",
            height: "7.5em",
            color: theme.palette.on.surface.main2,
            overflow: "hidden",
            fontSize: "12px",
        },
        teamText: {
            [theme.breakpoints.up('sm')]: {
                fontSize: "16px",
                padding: "25px 18px"
            },
            fontSize: "12px",
            padding: "12px 8px",
            display: "flex",
            flexDirection: "column",
        },
        readMore: {
            color: theme.palette.on.surface.contrastText,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            textAlign: "center",
            marginTop: "8px",
        }
    })
}

export default function TeamCard(props) {
    const theme = useTheme()
    const classes = styles(theme)()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const [imageDetailShown, setImageDetailShown] = React.useState(false)
    const [imageDetailOffset, setImageDetailOffset] = React.useState(0)
    const toggleImageDetail = (show) => {
        setImageDetailOffset(window.pageYOffset)
        setImageDetailShown(show)
    }
    const people = props.people? props.people : peopleStore.EmptyPeople()
    return(
        <div className={classes.wrapper}>
            {!sm && 
                <OverlayTeamCard 
                shown={imageDetailShown}
                imageURL={people.image}
                onBackgroundClick={() => toggleImageDetail(false)}
                people={people}
                top={`${imageDetailOffset}px`}
                />
            }
            <div className={`image_div ${classes.teamImage}`}
                style={{ backgroundImage: `url(${people.image})`}}/>
            <div className={classes.teamText}>
                <div className={`text ${classes.teamPosition}`}>
                    {people.position}
                </div>
                <div className={`text-large ${classes.teamName}`}>
                    {people.name}
                </div>
                <div className={`text ${classes.teamDescription}`}>
                    {people.description}
                </div>
                {!sm && 
                    <div 
                        className={`text-small ${classes.readMore}`}
                        onClick={() => toggleImageDetail(true)}
                    >
                        Read more + 
                    </div>
                }
            </div>
        </div>
    )
}