import { makeStyles, useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Client from "../../component/client/Client";
import ProjectGrid from "../../component/project-grid/ProjectGrid";

const projectListStyles = (theme) => {
    return makeStyles({
        title: {
            fontWeight: "bold",
            marginTop: "135px",
            marginBottom: "17px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "160px",
                marginBottom: "52px",
            },
        },
        projectList_wrapper: {
            [theme.breakpoints.up('sm')]: {
                margin: "126px 10vw 45px 10vw",
            },
            margin: "36px 5vw 45px 5vw",
        }
    })
}
export default function Project() {
    const theme = useTheme()
    const classes = projectListStyles(theme)()
    return (
        <div>
            <Helmet>
                <title> Nation Insights | Projects </title>
            </Helmet>
            <div className={classes.projectList_wrapper}>
                <div className={`${classes.title} text-x-large`}>
                    Projects
                </div>
                <ProjectGrid />
            </div>
            <Client/>
        </div>
    )
}