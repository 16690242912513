import { Collapse, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
const styles = (theme) => {
    return makeStyles({
    toggleControl: {
        [theme.breakpoints.up('sm')]: { 
            marginTop: "24px",
        },
        marginTop: "35px",
        cursor: "pointer",
        fontWeight: "500",
        color: theme.palette.on.surface.contrastText,
    },
    snippetWrapper: {},
    contentWrapper: {}
})}
export default function ServiceItem (props) {
    const theme = useTheme()
    const initialCollapse = props.initialCollapse? true : false
    const [collapse, setCollapse] = React.useState(initialCollapse)
    const toggleCollapse = (event) => {
        setCollapse(!collapse)
    }
    const classes = styles(theme)()

    return (
        <div>
            <div>
                <Collapse in={collapse} ease="enter">
                    <div className={`text ${classes.contentWrapper}`}>
                        {props.children}
                    </div>
                </Collapse>
            </div>
            <div className={`${classes.toggleControl} text`}
                onClick={(e) => toggleCollapse(e)}>
                {collapse? 'Less -' : 'Read More +'}
            </div>
        </div>
    )
}