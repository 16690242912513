import { Link } from 'react-router-dom';
import HeaderMenuItem from './HeaderMenuItem';
import logo from '../../../assets/logo/logo_white.svg';
import HeaderStyles from './HeaderStyles';
import { makeStyles, useTheme } from '@material-ui/core';

const styles = makeStyles({
    spread: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        marginRight: '50px',
        alignItems: 'center'
    }
})

export default function HeaderSm(props) {
    const theme = useTheme()
    const headerClasses = HeaderStyles(theme)
    const classes = styles()
    const toggleSelected = (event, selectedMenu) => {
        props.setSelectedMenu(selectedMenu)
    }
        return (
            <nav>
                <ul className={`${headerClasses.header} ${classes.spread}`}>
                    <li className={`${headerClasses.headerMenu} logo`}
                        onClick={(e) => toggleSelected(e, props.menuItem[0])}>
                        <Link to="/">
                            <img src={logo} height="28px" alt="Nation"/>
                        </Link>
                    </li>
                    <HeaderMenuItem 
                        text={props.menuItem[1]}
                        link={props.menuLink[1]}
                        selected={props.selected === props.menuItem[1]}
                        menuOnClick={toggleSelected}
                    >
                        {props.menuItem[1]}
                    </HeaderMenuItem>
                    <HeaderMenuItem
                        text={props.menuItem[2]}
                        link={props.menuLink[2]}
                        selected={props.selected === props.menuItem[2]}
                        menuOnClick={toggleSelected}
                    >
                        {props.menuItem[2]}
                    </HeaderMenuItem>
                    <HeaderMenuItem
                        text={props.menuItem[3]}
                        link={props.menuLink[3]}
                        selected={props.selected === props.menuItem[3]}
                        menuOnClick={toggleSelected}
                    >
                        {props.menuItem[3]}
                    </HeaderMenuItem>
                    <HeaderMenuItem
                        text={props.menuItem[4]}
                        link={props.menuLink[4]}
                        selected={props.selected === props.menuItem[4]}
                        menuOnClick={toggleSelected}
                    >
                        {props.menuItem[4]}
                    </HeaderMenuItem>
                </ul>
            </nav>
        )

}