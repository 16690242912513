import { Fade, makeStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React from "react"

// https://material-ui.com/styles/basics/#adapting-based-on-props
// I'm not sure if we can combine passing props with theme
// if we have the requirement to do so, better create seperate styles (another makeStyles)
const styles = makeStyles({
    imageDetailWrapper: props => ({
        width: '100%',
        height: '100%',
        position: "absolute",
        zIndex: "10000",
        left: "0",
        top: props.top,
    }),
    imageDetailBackground: {
        backgroundColor: "#000000",
        width: "100%",
        height: "100vh",
        opacity: "0.5",
        position: 'absolute',
    },
    imageDetail: {
        maxHeight: '75vh',
        maxWidth: '75vw',
        top: '10vh',
        cursor: "default"
    },
    imageDetailContainer: {
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column",
        cursor: "zoom-out"
    },
    closeCaption: {
        display:"flex",
        color: "#ffffff",
        marginBottom:"20px",
        marginTop: "-25px",
        width: "100%",
        justifyContent: "flex-end",
        cursor: "pointer"
    }
})

export default function OverlayImage(props) {
    const onBackgroundClick = (event) => {
        props.onBackgroundClick(event)
    }
    const classes = styles(props)
    React.useEffect(() => {
        if (props.shown) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [props.shown]);
    return (
        <Fade in={props.shown}>
            <div top="200px" className={classes.imageDetailWrapper}>
                <div className={classes.imageDetailBackground}/> 
                    <div 
                        onClick={(e) => onBackgroundClick(e)}
                        className={classes.imageDetailContainer}>
                        <div>
                            <div className={`${classes.closeCaption} text-large`}>
                                <CloseIcon/>
                            </div>
                        <img 
                            onClick={(e) => e.stopPropagation()}
                            className={classes.imageDetail} src={props.imageURL}
                            alt=""
                        />
                        </div>
                    </div>
            </div>
        </Fade>   
    )
}