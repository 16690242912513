import { useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HeaderStyles from './HeaderStyles';

export default function 
HeaderMenuItem(props) {
    const theme = useTheme()
    const headerClasses = HeaderStyles(theme)
    const onClick = (e) => {
        if (props.menuOnClick) {
            props.menuOnClick(e, props.text)
        }
    }
    const xs = props.xs
    return (
        <li className={
            `text ${xs?headerClasses.headerMenuXs:headerClasses.headerMenu}
            ${props.selected? "selected": ""}
            `
        }
            onClick={onClick}>
            <Link to={props.link}>
                {props.children}
            </Link>
        </li>
    )
}