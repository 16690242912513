import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import bannerStore from '../../../store/banner'
import TeamGrid from "../../component/team-grid/TeamGrid";
import { Helmet } from 'react-helmet'

const styles = (theme) => {
    return makeStyles({
        wrapper: {
            margin: "135px 5vw 12px 5vw",
            paddingBottom: "20px",
            [theme.breakpoints.up('sm')]: { 
                margin: "160px 10vw 40px 10vw",
            },
        },
        teamTitle: {
            width: "100%",
            fontWeight: "bold",
            paddingBottom: "20px",
            [theme.breakpoints.up('sm')]: {
                paddingBottom: "70px",
            }
        }
    })
}
export default function Team(props) {
    const theme = useTheme()
    const classes = styles(theme)()
    const [banner, setBanner] = React.useState([])
    React.useEffect(() => {
        bannerStore.GetBanner()
            .then(apiData => {
                let bnr = apiData.data.data.map((b) => {
                    return bannerStore.Banner(
                        b.id, b.name, b.image, b.priority
                    )
                })
                setBanner(bnr)
            })
    }, [])
    return (
        <div>
            <Helmet>
                <title> Nation Insights | Teams </title>
            </Helmet>
            <div className={classes.wrapper}>
                <div className={`text-x-large ${classes.teamTitle}`}>
                    Meet Our Team
                </div>
                <TeamGrid/ >
            </div>
        </div>
    )
}