import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet";
import BannerText from "../../component/banner-text/BannerText";
import ImageBanner from "../../component/image-banner/ImageBanner";
import ServiceItem from "./ServiceItem";

const styles = (theme) => makeStyles ({
    banner: {
        height: '100vh',
        fontWeight: 'bold',
    },
    serviceWrapper: {
        [theme.breakpoints.up('sm')]: { 
            marginLeft: "10vw",
            marginRight: "10vw",
            marginTop: "50px",
            marginBottom: "80px",
        },
        height: "auto",
        background: theme.palette.surface.main,
        color:  theme.palette.on.surface.main,
        marginLeft: "5vw",
        marginRight: "5vw",
        marginTop: "23px",
        marginBottom: "52px",
    },
    serviceItemTitle: {
        fontSize: "28px",
        fontWeight: "bold",
        marginBottom: "7px",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
    serviceTitle: {
        fontWeight: "bold",
        textAlign: "left",
        [theme.breakpoints.up('sm')]: { 
            marginBottom: "52px",
        },
        marginBottom: "24px",
    },
    serviceItemSubTitle: {
        marginTop: "35px",
        fontWeight: "500",
        marginBottom: "12px",
    },
    serviceItemContent: {
        color:  theme.palette.on.surface.main2,
        lineHeight: "27px",
    },
    serviceItemSummary: {
        color:  theme.palette.on.surface.main3,
    },
    serviceItemTitleDecor: {
        marginLeft: "13px",
        display: 'inline-block',
        paddingTop: "5px",
        [theme.breakpoints.up('sm')]: { 
            paddingTop: "10px",
            marginLeft: "25px",
        },
    }
})

export default function Service() {
    const theme = useTheme()
    const classes = styles(theme)()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <div>
            <Helmet>
                <title> Nation Insights | Services </title>
            </Helmet>
            <div className ={`text-banner ${classes.banner}`}>
                <ImageBanner file="/stub-image/services.jpg">
                    <BannerText>
                        We apply a range of approaches, including
                        ethnography, expert interviews, competitive 
                        and cultural analysis in the following areas of expertise
                    </BannerText>
                </ImageBanner>
            </div>
            <div className={classes.serviceWrapper}>
                <div className={`text-banner ${classes.serviceTitle}`}>
                    Our Services
                </div>
                <Grid container spacing={sm? 10: 5}>
                    <Grid item xs={12} sm={4}>
                        <div className={`text ${classes.serviceItemTitle}`}>
                            Insights
                            <div className={classes.serviceItemTitleDecor}>
                                <svg width={sm? "32" : "24"} height={sm? "32" : "24"} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#EF4623"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className={`text ${classes.serviceItemSummary}`}>
                            Understanding people's choices & motivations
                        </div>
                        <ServiceItem>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Segmentation
                            </div>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Exploratory Research
                            </div>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Customer Journeys
                            </div>
                        </ServiceItem>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={`text ${classes.serviceItemTitle}`}>
                            Strategy
                            <div className={classes.serviceItemTitleDecor}>
                                <svg width={sm? "32" : "24"} height={sm? "32" : "24"} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#315EB9"/>
                                </svg>
                            </div>
                        </div>
                        <div className={`text ${classes.serviceItemSummary}`}>
                            Developing unique angles that can give brands an edge
                        </div>
                        <ServiceItem>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Brand Strategy
                            </div>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Customer Value Propositions
                            </div>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Communication Strategies
                            </div>
                        </ServiceItem>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={`text ${classes.serviceItemTitle}`}>
                            Foresight
                            <div className={classes.serviceItemTitleDecor}>
                                <svg width={sm? "32" : "24"} height={sm? "32" : "24"} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#5DEF07"/>
                                </svg>
                            </div>
                        </div>
                        <div className={`text ${classes.serviceItemSummary}`}>
                            Uncovering emerging signals & untapped opportunities
                        </div>
                        <ServiceItem>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Long-term Innovation
                            </div>
                            <div className={`text-large ${classes.serviceItemSubTitle}`}>
                                Scenario Planning
                            </div>
                        </ServiceItem>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}