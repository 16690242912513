import React from 'react';
import { Collapse, Grid, useTheme, useMediaQuery } from "@material-ui/core";

import ProjectCard from "./ProjectCard";
import projectStore from "../../../store/project"
import { makeStyles } from '@material-ui/styles';

const styles = (theme) => {
    return makeStyles({
        loadMore_wrapper: {
            paddingTop: "32px",
            textAlign: "center",
        },
        loadMore: {
            "&:hover": {
                color: theme.palette.on.surface.contrastText,
                cursor: "pointer"
            },
        },
        project_grid__wrapper: {}
    })
}

export default function ProjectGrid() {
    const [projects, setProjects] = React.useState([])
    const [after, setAfter] = React.useState(null)
    const [limit, setLimit] = React.useState(3)
    const [showLoadMore, setShowLoadMore] = React.useState(true)
    const [hasMore, setHasMore] = React.useState(true)
    const [firstLoad, setFirstLoad] = React.useState(true)

    const theme = useTheme()
    const classes = styles(theme)()
    const initialLimit = 6
    let sm = useMediaQuery(theme.breakpoints.up('sm'))
    const [nextLimit, setNextLimit] = React.useState(3)
    const getNextLimit = () => {
        return nextLimit
    }
    React.useEffect(() => {
        if (sm) {
            setNextLimit(3)
        } else {
            setNextLimit(2)
        }
    }, [sm])
    React.useEffect(() => {
        let mounted = true;
        if (firstLoad) {
            loadMore()
            setFirstLoad(false)
        }
        return () => {
            mounted = false;
        }
    }, [after])

    React.useEffect(() => {
        if (!hasMore) {
            setTimeout(() => {
                setShowLoadMore(false)
            }, 300)
        }
    }, [hasMore])

    // TODO consider images of multiple sizes for lighter payload
    const loadMore = () => {
        var loadData = null
        if (firstLoad) {
            loadData = () => projectStore.GetProjects(initialLimit, after)
        } else {
            loadData = () => projectStore.GetProjects(getNextLimit(), after)
        }
        loadData()
            .then(apiData => {
                var items = apiData.data.data.map((i) => {
                    let p = projectStore.ParseProject(i)
                    p.images = i.images
                    return p
                })
                var newProjects = projects.concat(items);
                setProjects(newProjects)
                setHasMore(apiData.data.has_more)
                setAfter(newProjects[newProjects.length - 1].priority)
            })
        setLimit(getNextLimit())
    }
    return (
        <div className={classes.project_grid__wrapper}>
            {/* to be precise, the spacing should be 1.5, but it is only available in material ui version 5 */}
            <Grid container spacing={1}>
                {projects.map(function(p, i) {
                    let startWithCollapse = true
                    if (i < 6) {
                        startWithCollapse = false
                    }
                    return (<Grid item sm={4} xs={6}>
                        <ProjectCard startWithCollapse={startWithCollapse}
                                data={p}
                                key={p.id}
                            />
                    </Grid>)
                })}
                <Grid item xs={12}>
                    <Collapse in={showLoadMore}>
                        <div className={`text ${classes.loadMore_wrapper}`}>
                            <span onClick={() => loadMore()} className={classes.loadMore}>
                                Show More +
                            </span>
                        </div>        
                    </Collapse>
                </Grid>
            </Grid>
        </div>
    )
}