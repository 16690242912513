import { Component } from "react";
import './ComingSoon.css'
import { Helmet } from 'react-helmet'
class ComingSoon extends Component {
    render() {
        return (
            <div className="coming_soon__wrapper">
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                </Helmet>
                <span className="text-large coming_soon">{this.props.pageTitle}</span>
                <span className="text coming_soon"> Coming Soon! </span>
            </div>
        )
    }
}

export default ComingSoon