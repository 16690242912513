import { makeStyles } from "@material-ui/styles"
import { useTheme } from "@material-ui/core";

const BannerText = (props) => {
    const theme = useTheme()
    
    const styles = makeStyles({
        wrapper: {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '10vw',
                paddingRight: '10vw',    
            },
            display: 'flex',
            position: 'absolute',
            paddingLeft: '5vw',
            paddingRight: '5vw',
            justifyContent:'center',
            flexDirection: 'column',
            height: '100%',
            zIndex: '100',
        }
    })
    const classes = styles()
    return (
        <div className={`${classes.wrapper} text-banner`}>
            {props.children}
        </div>
    )
}

export default BannerText