import axios from "axios"

const ParseProject = (p) => {
    let project = {
        id: p.id,
        name: p.name,
        description: p.description,
        imgUri: p.img_uri,
        summary: p.summary,
        team: p.team,
        bottomImage: p.bottom_image,
        bottomDescription: p.bottom_description,
        bottomImageCaption: p.bottom_image_caption,
        thumbnails: p.thumbnails,
        createdAt: p.created_at,
    }
    if (p.prev) {
        project.prev = {
            id: p.prev.id,
            name: p.prev.name,
            summary: p.prev.summary,
            imageUri: p.prev.img_uri,
        }
    }
    if (p.next) {
        project.next = {
            id: p.next.id,
            name: p.next.name,
            summary: p.next.summary,
            imageUri: p.next.img_uri,
        }
    }
    project.priority = p.priority
    return project
}
const Project = (
    id,
    name,
    description,
    imgUri,
    summary,
    team,
    bottomImage,
    bottomDescription,
    bottomImageCaption,
    createdAt,
    prev,
    next,
    thumbnails) => {
    let project = {
        id: id,
        name: name,
        description: description,
        imgUri: imgUri,
        summary: summary,
        team: team,
        bottomImage: bottomImage,
        bottomDescription: bottomDescription,
        bottomImageCaption: bottomImageCaption,
        thumbnails: thumbnails,
        createdAt: createdAt,
    }
    if (prev) {
        project.prev = {
            id: prev.id,
            name: prev.name,
            summary: prev.summary,
            imageUri: prev.img_uri,
        }
    }
    if (next) {
        project.next = {
            id: next.id,
            name: next.name,
            summary: next.summary,
            imageUri: next.img_uri,
        }
    }
    return project
}

const EmptyProject = () =>{
    return Project("", "", "", "")
} 

const ProjectDetail = (project, images) => {
    return {
        project: project,
        images: images
    }
}

const EmptyProjectDetail = () => {
    return {
        project: EmptyProject,
        images: []
    }
}

const ProjectImage = (id, file, priority, projectID) => {
    return {
        id: id,
        file: file,
        priority: priority,
        projectID: projectID
    }
}

const apiRoot = process.env.REACT_APP_API_ROOT

const GetProjects = (limit, after) => {
    return axios.get(`${apiRoot}/project?limit=${limit}&after=${after}`)
}

const GetProjectDetail = (id) => {
    return axios.get(`${apiRoot}/project/${id}`)
}

const exported = {
    Project,
    ParseProject,
    EmptyProject,
    ProjectDetail,
    EmptyProjectDetail,
    ProjectImage,
    GetProjectDetail,
    GetProjects
}

export default exported