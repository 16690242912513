import projectStore from "../../../store/project"
import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Collapse, makeStyles, useTheme } from '@material-ui/core'
import { Link } from "react-router-dom"
import "./ProjectCard.css"

const styles = (theme) => {
    return makeStyles({
        projectCard_image: {
            height: "160px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            [theme.breakpoints.up('sm')]: {
                height:"240px"
            },
        },
        projectCard_textWrapper: {
            width:"100%",
            fontSize: "18px",
        },
        projectCard_hoverWrapper: {
            padding: "36px 19px",
            overflow: "hidden",
        },
        projectCard_title: {
            fontWeight: "bold",
            "& a": {
                color: "inherit"
            },
            overflow:"hidden",
            lineHeight: "1.25em",
            maxHeight: "3.8em",
            marginBottom: "12px",
        },
        projectCard_category: {
            color: theme.palette.on.surface.main3,
            marginBottom: "18px",
        },
        projectCard_description: {
            "& a": {
                color: "inherit"
            },
            overflow:"hidden",
            lineHeight: "1.25em",
            [theme.breakpoints.up('sm')]: {
                height: "5em",
            },
            height: "5em",
            color: theme.palette.on.surface.main2
        },
        projectCard_wrapper: {
            backgroundColor: theme.palette.surface.main,
            color: theme.palette.on.surface.main,
            "&:hover": {
                backgroundColor: theme.palette.surface.active + "!important"
            },
            "& text-small:hover": {
                color: theme.palette.on.surface.active + "!important"
            },
            "& a": {
                textDecoration: "none",
                color: theme.palette.on.surface.main
            },
            "&:hover a": {
                color: theme.palette.on.surface.active
            },
            "&:hover .text-small": {
                color: theme.palette.on.surface.active
            },
            border: `1px solid ${theme.palette.primary.shadow}`,
            height: "100%",
            minHeight: "310px",
            [theme.breakpoints.up('sm')]: {
                minHeight: "435px",
            },
        },
        projectCover_wrapper: {
            display: "flex",
            flexDirection: "column"
        }
    })
}
export default function ProjectCard(props) {
    const theme = useTheme()
    const classes = styles(theme)()
    const [collapseBegin, setCollapseBegin] = React.useState(false)
    const [hover, setHover] = React.useState(false)
    const project = projectStore.Project(
        props.data.id,
        props.data.name,
        "",
        "",
        props.data.summary
    )

    const onMouseHover = (isHover) => {
        if (splideRef.current && playButton.current && pauseButton.current) {
            if (isHover) {
                playButton.current.click()
            } else {
                pauseButton.current.click()
            }
        }

        setHover(isHover)
    }

    React.useEffect(() => {
        setCollapseBegin(true)
        onMouseHover(false)
    },[project.id])

    if (!props.data) {
        return (<div></div>)
    }
    
    const images = props.data.images
    let splideRef = React.createRef()
    let playButton = React.createRef()
    let pauseButton = React.createRef()
    let item = (
    <div className={classes.projectCard_wrapper}
        onMouseEnter={() => onMouseHover(true)}
        onMouseLeave={() => onMouseHover(false)}
    >   
        {images &&
            
                <Splide ref={splideRef} options={{
                    autoplay: true,
                    rewind: true,
                    pauseOnHover: false,
                    pagination: false,
                    interval: 1200,
                    arrows: false,
                    hasAutoplayControls: true
                }}
                renderControls={ () => (
                    <div class="splide__autoplay">
                        <button ref={playButton} class="splide__play">play</button>
                        <button ref={pauseButton} class="splide__pause">pause</button>
                    </div>
                )}>
                    {images.map(i => 
                            <SplideSlide>
                            <Link to={`/project/detail/${project.id}`}>
                                <div 
                                    className={classes.projectCard_image}
                                    style={{ backgroundImage: `url(${i.file})`}}
                                    key={i.file}
                                />               
                            </Link>

                            </SplideSlide>
                    )}
                </Splide>
        }
        <Link to={`/project/detail/${project.id}`}>
            <div className={classes.projectCard_hoverWrapper}>
                <div className={classes.projectCard_textWrapper}>
                    <div className={`${classes.projectCard_title} text`}>{project.name}</div>
                    <div className={`${classes.projectCard_description} text-small`}>
                        {project.summary}
                    </div>
                </div>
            </div>
        </Link>
    </div>)

    if (props.startWithCollapse) {
        return (
        <Collapse in={collapseBegin} ease="enter">
            {item}
        </Collapse>
        )
    } else {
        return item
    }
}