import { Component } from "react";
import './TypeWriter.css'

class TypeWriter extends Component {
    constructor(props) {
        super(props);
        var texts = [
            "Culture\u00a0",
            "Implication\u00a0",
            "Observation\u00a0",
            "Insights\u00a0",
            "Strategy\u00a0",
            "Foresight\u00a0"
        ]
        this.state = {
            typewriterTexts: texts,
            textIndex: 0,
            typedText: "",
            fullText: texts[0],
            typeDelayMs: 150,
            deleteDelayMs: 60
        }
    }

    render() {
        return (
            <div className="typewriter__wrapper text">
                <div className="typewriter">
                    <div className="caret">
                        {this.state.typedText}
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        setTimeout(() => this.type(), 300)
    }

    componentWillUnmount() {
    }

    findNextIndex() {
        return (this.state.textIndex + 1) % this.state.typewriterTexts.length
    }

    type() {
        /*
         TODO this constant substring might be bad for performance
         not only js need to recalculate heap for each substring, 
         the window need to resize component size

         if problem is found. One workaround is to change the colour of 
         the characters to match the background. So, the size of 
         strings and window element is static, only pixel colours need to change
        */
        var typedText = this.state.typedText
        var fullText = this.state.fullText
        if (typedText === "") {
            this.setState({
                typedText: fullText.substring(0, 1)
            }, () => setTimeout(
                    () => this.type(),
                    this.state.typeDelayMs
                )
            )
            return
        }

        var nextAction
        if (typedText.length === fullText.length) {
            nextAction = () => this.delete()
            
        } else {
            nextAction = () => this.type()
        }

        this.setState({
            typedText: fullText.substring(0, typedText.length + 1)
        }, () => setTimeout(
                nextAction,
                this.state.typeDelayMs
            )
        )
        
    }

    delete() {
        var typedText = this.state.typedText
        var fullText = this.state.fullText
        if (typedText.length === 0) {
            var index = this.findNextIndex()
            this.setState({
                fullText: this.state.typewriterTexts[index],
                textIndex: index
            }, () => setTimeout(
                    () => this.type(),
                    this.state.deleteDelayMs * 2
                )
            )
        } else {
            this.setState({
                typedText: fullText.substring(0, typedText.length - 1)
            }, () => setTimeout(
                    () => this.delete(),
                    this.state.deleteDelayMs
                )
            )
        }
    }
}

export default TypeWriter