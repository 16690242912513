import './App.css';

import Client from './client/Client'
import { Helmet } from 'react-helmet'
import "web-animations-js/web-animations.min"

function App() {
  return (
    <div className="App">
      <Helmet>
        <title> Nation Insights</title>
      </Helmet>
      <Client/>
    </div>
  );
}

export default App;
