import { Collapse, Grid, makeStyles } from "@material-ui/core";
import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import peopleStore from '../../../store/people'
import TeamCard from "./TeamCard";

const styles = (theme) => {
    return makeStyles({
        loadMore_wrapper: {
            marginTop: "18px",
            textAlign: "center",
        },
        loadMore: {
            "&:hover": {
                color: theme.palette.on.surface.contrastText,
                cursor: "pointer"
            },
        },
        project_grid__wrapper: {}
    })
}

export default function TeamGrid() {
    const [data, setData] = React.useState([])
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    const [showMore, setShowMore] = React.useState(false)
    const classes = styles(theme)()
    React.useEffect(() => {
        peopleStore.GetPeople()
            .then(apiData => {
                let ppl = apiData.data.data.map((p) => {
                    return peopleStore.People(
                        p.id, p.name, p.description, p.image, p.position,
                    )
                })
                setData(ppl)
            })
    },[])

    const toggleShowMore = () => {
        setShowMore(!showMore)
    }
    const getItemsOnTop = () => {
        if (lg) {
            return 4
        }
        if (sm) {
            return 3
        }
        return 2
    }
    return (
        <div>
        <Grid container spacing={md?4:1}>
            {data.map(function (p, i) {
                if (i >= getItemsOnTop()) {
                    return ''
                }
                return (
                <Grid item xs={6} sm={4} lg={3}>
                    <TeamCard 
                        itemIdx={i}
                        key = {p.id}
                        people={p}
                    />
                </Grid>
                )
            })}
            <Grid item xs={12}>
                <Collapse in={!showMore}>
                    <div className={`text ${classes.loadMore_wrapper}`}>
                        <span onClick={() => toggleShowMore()} className={classes.loadMore}>
                            Show More +
                        </span>
                    </div>        
                </Collapse>
            </Grid>
        </Grid>
        <Collapse in={showMore} timeout={1300} easing="out">
        <Grid container spacing={md?4:1}>
                {data.map(function (p, i) {
                    if (i < getItemsOnTop()) {
                        return ''
                    }
                    return (
                    <Grid item xs={6} sm={4} lg={3}>
                        <TeamCard 
                            itemIdx={i}
                            key = {p.id}
                            people={p}
                        />
                    </Grid>
                    )
                })}
                <Grid item xs={12}>
                    <Collapse in={showMore}>
                        <div className={`text ${classes.loadMore_wrapper}`}>
                            <span onClick={() => toggleShowMore()} className={classes.loadMore}>
                                Show Less -
                            </span>
                        </div>        
                    </Collapse>
                </Grid>
        </Grid>     
        </Collapse>
    </div>
    )
}