import { makeStyles, useTheme } from "@material-ui/core"

const styles = (theme) => {
    return makeStyles({
        wrapper: {
            margin: 'auto',
            height: '100%',
            color: theme.palette.on.primary.main,
            position: 'relative',
            overflow: 'hidden',
        },
        image: {
            position: 'absolute',
            top: '0',
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: '100%',
            zIndex: '-100'
        },
        overlay: {
            backgroundColor: '#000000',
            opacity: "0.3",
            position: 'absolute',
            top: '0',
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: '100%',
            zIndex: '-90'
        },
        content: {
            position: 'absolute',
            top: '0',
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: '100%',
            zIndex: '100',
        }
    })
}

const ImageBanner = (props) => {
    const theme = useTheme()
    const classes = styles(theme)()
    return (
        <div className={classes.wrapper}>
            <div className={classes.image+` image_div`}
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.file})` }}>
            </div>
            <div className={classes.overlay}/>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>
    )
}

export default ImageBanner