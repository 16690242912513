import React from 'react';
import { AppBar, Slide, useMediaQuery, useTheme } from "@material-ui/core";
import HeaderSm from "./HeaderSm";
import HeaderXs from "./HeaderXs.js"
import HeaderStyles from './HeaderStyles';

const menuItem = ["Home", "Project", "Services", "Teams"]
const menuLink = ["/", "/project", "/service", "/team"]

export default function Header(props) {
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const [selected, setSelected] = React.useState("home")

    const toggleSelected = (selectedMenu) => {
        setSelected(selectedMenu)
    }
    const classes = HeaderStyles(theme)
    const [overrideHide, setOverrideHide] = React.useState(props.overrideHide)
    const headerContent = () => {return (
        <AppBar className={`${classes.wrapper}`}>
            { sm ? 
                    <HeaderSm 
                        menuItem={menuItem}
                        menuLink={menuLink}
                        setSelectedMenu={toggleSelected}
                        selected={selected}
                    /> :
                    <HeaderXs 
                        menuItem={menuItem}
                        menuLink={menuLink}
                        setSelectedMenu={toggleSelected}
                        selected={selected}
                    /> } 
            </AppBar>
    )}
    return(
        <div>
            { !props.overrideHide &&
            <Slide appear={false}
                direction="down"
                in={!props.scrollTrigger}
                mountOnEnter
                >
                { headerContent() }
            </Slide>
            }
            { props.overrideHide &&
            <Slide appear={true}
                direction="down"
                in={!props.hideHeader}
                mountOnEnter>
                { headerContent() }
            </Slide>
            }
        </div>
    )
}

function OverrideHide(props) {
    const {children} = props
    return (
        <Slide 
            appear={true}
            direction="down"
            in={!props.hide}
            timeout={{
                appear: 2000,
                enter: 500,
                exit: 500,
        
            }}
            mountOnEnter
            unmountOnExit
        >
            {children}
        </Slide>
    )
}

// TODO this does not hide properly when scrolling inside projetc detail.
// to do so, you might need to override material ui scroll detection
// how to improve? (probably minor tho)
function HideOnScroll(props) {
    const {children} = props
    return (
        <Slide 
            appear={true}
            direction="down"
            in={!props.scrollTrigger}
            mountOnEnter
            unmountOnExit
            >
            {children}
        </Slide>
    )
}