import axios from "axios"

const Banner = (id, name, image, priority) => {
    return {
        id: id,
        name: name,
        file: image,
        priority: priority,
    }
}

const EmptyBanner = () => {
    return Banner("","","",0)
}

const apiRoot = process.env.REACT_APP_API_ROOT

const GetBanner = () => {
    return axios.get(`${apiRoot}/banner`)
}

const exported = {
    Banner,
    EmptyBanner,
    GetBanner
}
export default exported