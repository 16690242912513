import { Collapse, makeStyles } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import React from "react"

const styles = (theme) => makeStyles({
    clientItemWrapper: {
        minHeight: "50px",
        marginBottom: "16px",
        marginTop:"16px"
    },
    categoryWrapper: {
        marginBottom: "10px",
        [theme.breakpoints.up('sm')]: {
            marginBottom: "20px"
        },
    },
    category: {},
    categoryText: {
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            fontSize: "28px",    
        }
    },
    itemsWrapper: {
        boxSizing: "border-box",
        padding: "0",
        width: "100%",
    },
    snippetWrapper: {
        width: "100%",

    },
    moreItemsWrapper: {
        boxSizing: "border-box",
        padding: "0",
        width: "100%",
    },
    toggleControl: {
        cursor: "pointer"
    },
    singleItemText:{
        marginBottom: "10px",
        [theme.breakpoints.up('sm')]: {
            marginBottom: "20px",
            fontSize: "28px",
        },
    }
})
export default function ClientItem(props) {
    const items = props.items? props.items : []
    const theme = useTheme()
    const classes = styles(theme)()
    const initialCollapse = props.initialCollapse? true: false
    const [collapse, setCollapse] = React.useState(initialCollapse)
    const itemsShownInSnippet = (items.length < 3)? items.length : 3
    const toggleCollapse = (event) => {
        setCollapse(!collapse)
    }

    let snippet = items
    let moreItems = []
    if (items.length > itemsShownInSnippet) {
        snippet = items.slice(0, itemsShownInSnippet)
        moreItems = items.slice(itemsShownInSnippet, items.length)
    }
    
    return (
        <div className={classes.clientItemWrapper}>
            <div className={classes.categoryWrapper}>
                <div className={classes.category}>
                    <div className={`text ${classes.categoryText}`}>
                        {props.category}
                    </div>
                </div>
            </div>
            <div className={classes.itemsWrapper}>
                <div className={classes.snippetWrapper}>
                    {snippet.map(function(item, i) {
                        return (
                            <div className={`text-large ${classes.singleItemText}`} key={i}>
                                {item}
                            </div>
                        )
                    })}
                </div>
                <Collapse in={collapse} ease="enter" unmountOnExit>
                    <div className={classes.moreItemsWrapper}>
                        {moreItems.map(function(item, i) {
                            return (
                                <div className={`text-large ${classes.singleItemText}`}>
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </Collapse>
                {(items.length > itemsShownInSnippet) &&
                        <div className={`${classes.toggleControl} text`} onClick={(e) => toggleCollapse(e)}>
                            {collapse ? "See less -" : "See more +"}
                        </div>
                }
            </div>
            
        </div>
    )
}