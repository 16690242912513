import { makeStyles, useTheme } from "@material-ui/core"

const videoBannerStyles = (theme) => {
    return makeStyles({
    wrapper: {
        margin: 'auto',
        height: '100%',
        color: theme.palette.on.primary.main,
        position: 'relative',
        overflow: 'hidden'
    },
    videoWrapper: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    video: {
        position: 'absolute',
        top: '0',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        zIndex: '-100',
        objectPosition:"center",
        objectPosition: "cover",
    },
    overlay: {
        backgroundColor: '#000000',
        opacity: "0.4",
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '100vh',
        zIndex: '-90',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
})}

const VideoBanner = (props) => {
    const videoURI = props.videoURI
    const videoID = props.videoID
    const theme = useTheme()
    const classes = videoBannerStyles(theme)();
    return (
        <div className={classes.wrapper}>
            <div className={classes.videoWrapper}>
                <video id={videoID} className={classes.video} playsInline autoPlay muted loop>
                    <source src={videoURI} type="video/mp4"/>
                </video>
            </div>
            <div className={classes.overlay}/>
            {props.children}
        </div>
    )
}

export default VideoBanner