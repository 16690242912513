import React from 'react';
import { useMediaQuery, useTheme } from "@material-ui/core";
import projectStore from "../../../store/project"
import dot from '../../../assets/logo/vermillion_dot.svg';
import Carousel from '../../component/carousel/Carousel';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import OverlayImage from '../../component/overlay-image/OverlayImage';
import { Helmet } from 'react-helmet';

const styles = (theme) => {
    return makeStyles({
        wrapper: {
            margin: "100px 0px 58px 0px",
            [theme.breakpoints.up('sm')]: {
                margin: "130px 10vw 80px 10vw",
            },
        },
        titleWrapper: {
            margin: "0px 30px",
            textAlign: "center",
            [theme.breakpoints.up('sm')]: {
                margin: "0px",
            },
        },
        title: {
            fontWeight: "bold",
            [theme.breakpoints.up('sm')]: {
                lineHeight: "1.15em",
            },
            lineHeight: "1.25em",
            color: theme.palette.on.surface.main,
        },
        date: {
            marginTop: "12px",
            color: theme.palette.on.surface.main3,
        },
        carouselWrapper: {
            marginTop: "21px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "52px",
            },
        },
        carouselControl: {
            textAlign: "center",
            marginTop: "9px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "16px",
            },
            color: theme.palette.on.surface.main3,
        },
        contentWrapper: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "30px",
                marginBottom: "0px",
                width: "40vw",
                width: "min(65vw, 650px)",
                margin: "auto",
            },
            margin: "24px 5vw 0px 5vw",
            fontSize: "18px",
        },
        projectSummary: {
            fontWeight: "500",
            [theme.breakpoints.up('sm')]: {
                marginTop: "72px",
            },
            color: theme.palette.on.surface.main,
        },
        bottomImageWrapper: {
            [theme.breakpoints.up('sm')]: {
                margin: "24px 0px 0px 0px",
            },
            float: "none",
            margin: "24px 0px 0px 0px",
        },
        bottomImage: {
            minHeight: "130px",
            width: "100%",
            cursor: "zoom-in",
        },
        bottomImageCaption: {
            marginTop: "12px",
            textAlign: "center",
            color: theme.palette.on.surface.main3,
            width: "100%",
        },
        description: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "72px",
            },
            marginTop: "36px",
            lineHeight: "1.85em",
            color: theme.palette.on.surface.main2,
            "& b": {
                color: theme.palette.on.surface.main,
            },
            "& em": {
                color: theme.palette.on.surface.main,
            },
            "& a": {
                color: theme.palette.on.surface.contrastText,
                textDecoration: "none",
            },
            "& blockquote":{
                fontStyle: "italic",
                borderLeftColor: theme.palette.on.surface.contrastText,
                borderLeftWidth: "medium",
                borderLeftStyle: "solid",
                paddingLeft: "12px",
                boxSizing: "border-box",
                margin: "5px",
            },
            "& q":{
                fontStyle: "italic",
                borderLeftColor: theme.palette.on.surface.contrastText,
                borderLeftWidth: "medium",
                borderLeftStyle: "solid",
                paddingLeft: "12px",
                boxSizing: "border-box",
                margin: "5px",
                quotes: "none",
                "&::before":{
                    content: "none",
                },
                "&::after":{
                    content: "none",
                }
            },
        },
        teamWrapper: {
            marginTop: "80px"
        },
        teamCaption: {
            fontWeight: "bold",
            marginBottom: "12px",
            display: "inline-block",
            color: theme.palette.on.surface.main,
        },
        teamDot: {
            marginLeft: "8px",
            display: "inline-block",
            position: "absolute",
            marginTop: "6px",
        },
        team: {
            lineHeight: "32px",
            color: theme.palette.on.surface.main2,
        },
        footerProjectsWrapper: {
            minHeight: "180px",
            padding: "32px 0 50px 0",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.up('sm')]: {
                padding: "12px 0 48px 0",
                minHeight: "205px",
            },
        },
        footerWrapper: {
            marginLeft: "5vw",
            marginRight: "5vw",
            borderTop: `1px solid ${theme.palette.on.surface.main2}`,
            [theme.breakpoints.up('sm')]: {
                marginLeft: "12vw",
                marginRight: "12vw",
            }
        },
        footerWrapperTitle: {
            marginTop: "54px",
            marginLeft: "18px",
            fontWeight: "bold",
        },
        footerCard: {
            backgroundColor: theme.palette.surface.main,
            width: "437px",
            width: "min(437px, 39vw)",
            display: "inline-block",
            [theme.breakpoints.up('sm')]: {
                padding: "22px 16px 24px 18px",
                width: "min(437px, 35vw)",
            },
            padding: "18px 8px 18px 8px",
            "& a": {
                color: theme.palette.on.surface.main,
                textDecoration: "none",
            },
            "& a:hover": {
                color: theme.palette.on.surface.contrastText,
                textDecoration: "none",
            }
            
        },
        footerCardPreviousArrowWrapper: {
            marginRight: "18px",
        },
        footerCardNextArrowWrapper: {
            display: "inline-block",
            marginLeft: "18px",
        },
        footerNavigationCaptionWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            [theme.breakpoints.up('sm')]: {
                justifyContent: "flex-start",
            }
        },
        footerNavigationCaption: {
            display: "inline-block",
            fontWeight: "bold",
            color: theme.palette.on.surface.main,
            marginTop: "-5px",
        },
        footerCardContent: {
            display: "flex",
            alignItems: "stretch",
            [theme.breakpoints.up('sm')]: {
                marginTop: "16px",
            },
            marginTop: "8px",
        },
        footerImage: {
            minWidth: "min(180px, 11.25vw)",
            minHeight: "98px",
            display: "inline-block",
            marginRight: "18px",
            boxSizing: "border-box",
            marginTop: "5px",
        },
        footerTextWrapper: {
            display: "inline-block",
        },
        footerTitle: {
            fontWeight: "bold",
            lineHeight: "25px",
            marginBottom: "10px",
            overflow: "hidden",
            [theme.breakpoints.up('sm')]: {
                marginBottom: "17px",
            }
        },
        footerSummary: {
            lineHeight: "23px",
            maxHeight: "3em",
            overflow: "hidden",
            fontSize: "16px",
        }
    })
}
export default function ProjectDetail(props) {
    /**
     * TODO 
     * 
     * use H1, H2, H3, etc. properly to structure the SEO.. starts from the header
     * https://www.searchenginejournal.com/on-page-seo/header-tags/#close
     * https://www.woorank.com/en/edu/seo-guides/html-header
     * https://neilpatel.com/blog/h1-tag/ 
     * **/

    const [data, setData] = React.useState(projectStore.EmptyProjectDetail())
    let projectId = props.id

    React.useEffect(() => {
        projectStore.GetProjectDetail(projectId)
            .then(apiData => {
                const p = apiData.data.project
                const imgs = apiData.data.images
                const pd = projectStore.ProjectDetail(
                    projectStore.ParseProject(p),
                    imgs.map((i) => {
                        return projectStore.ProjectImage(i.id, i.file, i.priority, i.project_id)
                    })
                )
                setData(pd)
            })
        setSlideIdx(1)
    }, [projectId])

    const [slideIdx, setSlideIdx] = React.useState(1)
    const onSlideMoved = (newIdx) => {
        setSlideIdx(newIdx + 1)
    }
    const createMarkup = (description) => {
        return { __html: description };
    }
    const theme = useTheme()
    const classes = styles(theme)()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const [imageDetailShown, setImageDetailShown] = React.useState(false)
    const [imageDetailOffset, setImageDetailOffset] = React.useState(0)
    const toggleImageDetail = (show) => {
        setImageDetailOffset(window.pageYOffset)
        setImageDetailShown(show)
    }
    const formatDate = (raw) => {
        let rawDate = new Date(Date.parse(raw))
        return rawDate.toLocaleString(undefined, {
            year: 'numeric', 
            month: 'short', 
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric', timeZone: 'Asia/Jakarta' }
        )
    }
    return (
        <div>
            <Helmet>
                <title>{data.project.name}</title>
                <meta name="description" property="description"
                    content={data.project.summary}
                />
                <meta property="og:description"
                    content={data.project.summary}
                />
            </Helmet>
            <div className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                    <h1 className={`text-x-large ${classes.title}`}>
                        {/* <h1> */}
                            {data.project.name}
                        {/* </h1> */}
                    </h1>
                    <div className={`text-small ${classes.date}`}>{formatDate(data.project.createdAt)}</div>
                </div>
                {data.project.bottomImage && 
                    <OverlayImage
                        shown={imageDetailShown}
                        imageURL={data.project.bottomImage}
                        onBackgroundClick={() => toggleImageDetail(false)}
                        top={`${imageDetailOffset}px`}
                    />
                }
                <div className={classes.carouselWrapper}>
                    <Carousel
                        content={data.images}
                        onSlideMoved={onSlideMoved}
                        height="500px"
                    />
                    <div className={`${classes.carouselControl} text-small`}>
                        ({slideIdx}/{data.images.length})
                    </div>
                </div>
                <div className={classes.contentWrapper}>
                    <div className={`${classes.projectSummary} text-large`}>
                        <span dangerouslySetInnerHTML={createMarkup(data.project.summary)} />
                    </div>
                    { data.project.bottomImage && 
                        <div className={classes.bottomImageWrapper} >
                            <img src={data.project.bottomImage}
                                className={classes.bottomImage}
                                onClick={() => toggleImageDetail(true)}
                                title="click to enlarge"
                                alt=""
                            />
                            <div className={`${classes.bottomImageCaption} text-small`}>
                                <span dangerouslySetInnerHTML={createMarkup(data.project.bottomImageCaption)} />
                            </div>
                        </div>
                    }
                    <div className={`${classes.description} text`} dangerouslySetInnerHTML={createMarkup(data.project.description)} />
                    {data.project.team &&
                        <div className={`${classes.teamWrapper}`}>
                            <div>
                                <div className={`text-large ${classes.teamCaption}`}>
                                    People in Charge
                                </div>
                                <div className={classes.teamDot}>
                                    <img src={dot} width={sm ? `24px` : `18px`} height={sm ? `24px` : `18px`} alt="" />
                                </div>
                            </div>
                            <div className={`text ${classes.team}`} dangerouslySetInnerHTML={createMarkup(data.project.team)} />
                        </div>
                    }
                </div>
            </div>
            <div className={`${classes.footerWrapper}`}>
                <div className={`${classes.footerWrapperTitle} text-large`}>
                    More Projects
                </div>
                <div className={`${classes.footerProjectsWrapper}`}>
                    {data.project.prev && 
                    <div className={classes.footerCard}>
                        <Link to={`/project/detail/${data.project.prev.id}`}>
                            <div className={classes.footerCardContent}>
                                {data.project.prev.imageUri && sm &&
                                    <div className={`image_div ${classes.footerImage}`}
                                        style={{ backgroundImage: `url(${data.project.prev.imageUri})` }} />
                                }
                                <div className={classes.footerTextWrapper}>
                                    <div className={`text ${classes.footerTitle}`}>
                                        {data.project.prev.name}
                                    </div>
                                    <div className={classes.footerSummary}>
                                        {data.project.prev.summary}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    }
                    {data.project.next && 
                    <div className={classes.footerCard}>
                        <Link to={`/project/detail/${data.project.next.id}`}>
                            {/* <div className={classes.footerNavigationCaptionWrapper}>
                                <div className={`text ${classes.footerNavigationCaption}`}>
                                    Next Project
                                </div> */}
                                {/* <div className={classes.footerCardNextArrowWrapper}>
                                    <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.6874 0.334961L19.471 1.55136L24.8837 6.9641H0.869629V8.68436H24.8837L19.471 14.097L20.6874 15.3134L28.1767 7.82417L20.6874 0.334961Z" fill="#221F20" />
                                    </svg>
                                </div>
                            </div> */}
                            <div className={classes.footerCardContent}>
                                {data.project.next.imageUri  && sm &&
                                    <div className={`image_div ${classes.footerImage}`}
                                        style={{ backgroundImage: `url(${data.project.next.imageUri})` }} />
                                }
                                <div className={`text ${classes.footerTextWrapper}`}>
                                    <div className={classes.footerTitle}>
                                        {data.project.next.name}
                                    </div>
                                    <div className={classes.footerSummary}>
                                        {data.project.next.summary}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}