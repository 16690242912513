import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Menu as MenuMaterialIcon } from '@material-ui/icons';
import HeaderMenuItem from './HeaderMenuItem';
import logo from '../../../assets/logo/logo_white.svg';
import { Collapse, makeStyles, useTheme } from '@material-ui/core';
import HeaderStyles from './HeaderStyles';

const styles = (theme) => {
    return makeStyles({
        navbar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&.dropdown': {
                backgroundColor: theme.palette.secondary.main,
                height: 'auto',
                justifyContent:'flex-end'
            }
        },
        collapse: {
            position: 'absolute',
            top: '0',
            backgroundColor: theme.palette.secondary.main,
            width: '100%',
            height: '100vh',
            '&.MuiCollapse-wrapper': {
                backgroundColor: '#ffffff'
            }
        },
        collapse_Wrapper: {
            height: '100vh',
            backgroundColor: theme.palette.secondary.main,
        },
        collapse_WrapperInner: {
            display: 'flex',
            flexDirection:'column' 
        },
        dropdownWrapper: {
            position: 'absolute',
        },
        dropdownMenu: {
            paddingTop: '20vh',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: theme.palette.secondary.main,
            justifyContent: 'center',
            flexGrow: 5,
        },
        hamburgerButton: {
            color: theme.palette.on.secondary.main,
            padding: '0px',
            marginRight: '-20px',
            '&.dropdown-open': {},
            display: 'visible',
            fontSize: "32px",
        },
        hamburgerButtonDark: {
            color: theme.palette.on.secondary.contrastText,
            padding: '0px',
            marginRight: '-20px',
            marginTop:'5px',
            fontSize: "32px",
            '&.dropdown-open': {
            },
        },
        dropdownFooter: {
            height: 'auto',
            padding: '0px 15px 20px 20px',
            overflow: 'hidden',
            flexGrow: 1,
        },
        copyright: {
            color: theme.palette.on.secondary.active,
        }
    })
}

export default function HeaderXs(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme()
    const headerClasses = HeaderStyles(theme)
    const classes = styles(theme)()

    const toggleOpen = (event, selectedMenu) => {
        if (selectedMenu !== "") {
            props.setSelectedMenu(selectedMenu)
        }
        if (!open) {
            setOpen(true)
        } else {
            setTimeout(
                () => setOpen(false),
                100
            )
        }
    }
    return (
        <nav> 
            <div className={`${headerClasses.header} ${classes.navbar}`}>
            <div className={headerClasses.headerMenu}
                onClick={() => {
                    props.setSelectedMenu(props.menuItem[0])
                }}>
                <Link to={props.menuLink[0]}>
                    <img src={logo} height="21px" alt="Nation"/>
                </Link>
            </div>
                <div>
                    <Button onClick={(e) => toggleOpen(e, "")} className={`${classes.hamburgerButton}`}>
                        <MenuMaterialIcon fontSize='inherit'/>
                    </Button>
                </div>
                
            </div>
                <Collapse in={open} className={`${classes.collapse}`} classes={{
                    wrapper: classes.collapse_Wrapper,
                    wrapperInner: classes.collapse_WrapperInner
                }}>
                    <div className={`${headerClasses.header} ${classes.navbar} dropdown`}>
                            <Button onClick={(e) => toggleOpen(e, "")} className={`${classes.hamburgerButtonDark} ${open?"dropdown-open":""}`}>
                                <MenuMaterialIcon fontSize='inherit'/>
                            </Button>
                    </div>
                    <ul className={`${classes.dropdownMenu} ${headerClasses.header}`}>
                        { props.menuItem.map((item, index) => {
                            // skip first item (home)
                            if (index === 0) return ""
                            return <HeaderMenuItem
                                xs={true}
                                link={props.menuLink[index]}
                                menuOnClick={(e) => toggleOpen(e, item)}
                                selected = {props.selected === item}
                                key={index}
                            >
                                    {item}
                            </HeaderMenuItem> 
                        })}
                    </ul>
                    <div className={classes.dropdownFooter}>
                        <div className={`text ${classes.copyright}`}>
                            Nation Insights &copy; 2021
                        </div>
                    </div>
            </Collapse>
        </nav>
    )
}