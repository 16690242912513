import Header from './component/header/Header'
import './Client.css';
import Home from './view/home/Home';
import Footer from './component/footer/Footer';
import TypeWriter from './component/type-writer/TypeWriter';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ComingSoon from './view/coming-soon/ComingSoon';
import Project from './view/project/Project';
import NotFound from './view/not-found/NotFound';
import ProjectDetail from './view/project/ProjectDetail';
import Team from './view/team/Team';
import React from 'react';
import { createMuiTheme, ThemeProvider, useScrollTrigger } from '@material-ui/core';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Service from './view/services/Service';

// guide in naming the palette 
// https://material.io/design/color/the-color-system.html#color-theme-creation
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#272727',
            shadow: '#F3F3F3'
        },
        secondary: {
            main: '#ef4623'
        },
        surface: {
            main: '#ffffff',
            background: '#eaeaea',
            active: '#185148',
        },
        on: {
            primary: {
                main: '#ffffff'
            },
            secondary: {
                main: '#ffffff',
                contrastText: '#221F20',
            },
            surface: {
                main: '#221F20',
                main2: '#575757',
                main3: '#949494',
                active: '#ffffff',
                contrastText: "#ef4623"
            }
        }
    },
})

// Scroll restoration hack from https://reactrouter.com/web/guides/scroll-restoration
function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}

export default function Client(props) {
    const contentContainer = React.createRef()
    const scrollTrigger = useScrollTrigger()
    const headerRef = React.createRef()

    const [overrideHideHeader, setOverrideHideHeader] = React.useState(false)

    // use childSetOverrideHeader to enable individual page to take over the behaviour
    // of header menu auto-hide
    const childSetOverrideHeader = (hide) => {
        setOverrideHideHeader(hide)
    }
    const [hideHeader, setHideHeader] = React.useState(false)
    const childSetHideHeader = (hide) => {
        setHideHeader(hide)
    }

    // first load is use to separate if the page is first loaded or has navigated around
    // being used to prevent home animation replays when user navigate to other pages and back to home
    const [firstLoad, setFirstLoad] = React.useState(true)
    const childSetFirstLoad = (value) => {
        setFirstLoad(value)
    }
    return (
        <ThemeProvider theme={theme}>
            <div className="client__wrapper">
            <BrowserRouter>
                <ScrollToTop/>
                <Header 
                    scrollTrigger={scrollTrigger}
                    ref={headerRef}
                    overrideHide={overrideHideHeader}
                    hideHeader={hideHeader}
                />
                <div className="content__wrapper" ref={contentContainer}>
                    <Switch>
                        <Route exact path="/">
                            <Home 
                                firstLoad={firstLoad}
                                setFirstLoad={childSetFirstLoad}
                                />
                        </Route>
                        <Route path="/project/detail/:id"
                            children = {({match}) => (
                                <ProjectDetail id={match.params.id} key={match.params.id}/>
                            )}>
                        </Route>
                        <Route path="/project">
                            <Project pageTitle="Projects"/>
                        </Route>
                        <Route path="/service">
                            <Service/>
                        </Route>
                        <Route path="/team">
                            <Team />
                        </Route>
                        <Route path="/client">
                            <ComingSoon pageTitle="Clients"/>
                        </Route>
                        <Route path="*">
                            <NotFound/>
                        </Route>
                    </Switch>
                </div>
                <TypeWriter/>
                <Footer 
                    backgroundColor={theme.palette.surface.background}
                    color={theme.palette.on.surface}
                    />
            </BrowserRouter>
            </div>
        </ThemeProvider>
    )
}