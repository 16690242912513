import VideoBanner from "../../component/video-banner/VideoBanner";
import { Grid, makeStyles, useTheme, useMediaQuery } from "@material-ui/core"
import BannerText from '../../component/banner-text/BannerText';
import React from "react";
import { Link } from "react-router-dom"

import projectStore from "../../../store/project"
import ProjectCard from "../../component/project-grid/ProjectCard";
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import './Home.css'


export default function Home(props) {

    const styles = (theme) => {
        return makeStyles({
            homeWrapper: {
            },
            banner: {
                height: '100vh'
            },
            homeBannerText: {
                paddingTop: "55vh",
                opacity: "0",
            },
            contentWrapper: {
                margin: "0px 5vw",
                [theme.breakpoints.up('sm')]: {
                    margin: "0px 10vw",
                },
            },
            caption: {
                fontSize: '14px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "24px",
                [theme.breakpoints.up('sm')]: {
                    paddingTop: "60px",
                    fontSize: '32px',
                },
                lineHeight: '1.5em',
                textAlign: "center",
            },
            CTALink: {
                fontSize: "16px",
                color: theme.palette.on.surface.contrastText,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "18px",
                marginBottom: "24px",
                [theme.breakpoints.up('sm')]: {
                    marginTop: "52px",
                    marginBottom: "58px",
                    fontSize: '28px',
                },
                "& a": {
                    color: "inherit",
                    textDecoration: "none",
                },
                fontWeight: '500',
            },
            CTAArrow: {
                marginLeft: "13px",
                paddingTop: "6px",
                [theme.breakpoints.up('sm')]: {
                    marginLeft: "28px",
                    paddingTop: "15px",
                },
            },
            projects: {
                [theme.breakpoints.up('sm')]: {
                    marginTop: "68px",
                },
                marginTop: "24px",
            },
            bannerNavigation: {
                paddingBottom: "35px",
                paddingTop: "40px",
                textAlign: "center",
                position: "absolute",
                left: "0",
                opacity: "0",
                bottom: "6vh",
                width: "100%",
                [theme.breakpoints.up('sm')]: {
                    bottom: "0px",
                },
                [theme.breakpoints.up('md')]: {
                    bottom: "12px",
                },
            }
        })
    }
    const animations = {
        homeBannerTextAnimation: [
            [
                { paddingTop: '55vh', opacity: '0', },
                // { opacity: '1', offset: 0.3},
                { paddingTop: '3vh', opacity: '1', }
            ],
            {
                duration: props.firstLoad === true? 900: 0,
                delay: props.firstLoad === true? 750: 0,
                easing: 'ease-out',
                fill: 'forwards',
            }
        ],
        homeBannerNavigationAnimation: [
            [
                { opacity: '0', bottom: '10vh' },
                { bottom: '-3vh', offset: 0.75 },
                { opacity: '1', bottom: '0vh' },
            ],
            {
                duration: 600,
                delay: 1750,
                fill: 'forwards',
                easing: 'ease-in'
            }
        ],
        homeBannerNavigationExitAnimation: [
            [
                {opacity: '1', bottom: '0vh'},
                {opacity: '0', bottom: '-5vh'},
            ],
            {
                duration: 300,
                fill: 'forwards',
                easing: 'ease-out',
            }
        ],
        homeBannerNavigationEnterAnimation: [
            [
                {opacity: '0', bottom: '-5vh'},
                {opacity: '1', bottom: '0vh'},
            ],
            {
                duration: 300,
                fill: 'forwards',
                easing: 'ease-out',
            }
        ]
    }
    const [projects, setProjects] = React.useState([])
    const [scrolledDown, setScrolledDown] = React.useState(false)
    // bannerText.
    const theme = useTheme()
    const classes = styles(theme)()
    let sm = useMediaQuery(theme.breakpoints.up('sm'))
    const homeTextRef = React.createRef();
    const bannerNavigationRef = React.createRef();
    const contentRef = React.createRef();
    const scrollToContent = () => {
        if (contentRef.current) {
            contentRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const homeWrapperRef = React.createRef();
    React.useEffect(() => {
        projectStore.GetProjects(3, null)
            .then(apiData => {
                var items = apiData.data.data.map((i) => {
                    let p = projectStore.Project(i.id, i.name, "", "", i.summary)
                    p.images = i.images
                    return p
                })
                setProjects(items)
            })
        if (homeTextRef.current) {
            homeTextRef.current.animate(...animations.homeBannerTextAnimation)
        }
        if (bannerNavigationRef.current) {
            bannerNavigationRef.current.animate(...animations.homeBannerNavigationAnimation)
        }
        if (props.setFirstLoad) {
            props.setFirstLoad(false)
        }
        let elemTop = homeTextRef.current.getBoundingClientRect().top
        let elemBottom = homeTextRef.current.getBoundingClientRect().bottom
        let elemHeightHalved = (elemBottom - elemTop)/2

        let scrollLimitTop = elemBottom - elemHeightHalved
        setScrollLimit(-1 * scrollLimitTop)
    }, [])

    React.useEffect(() => {
        if (!bannerNavigationRef.current) {
            return
        }
        if (scrolledDown) {
            bannerNavigationRef.current.animate(...animations.homeBannerNavigationExitAnimation)
        } else {
            if (props.firstLoad === false) {
                bannerNavigationRef.current.animate(...animations.homeBannerNavigationEnterAnimation)
            }
        }

    }, [scrolledDown])

    const [scrollLimit, setScrollLimit] = React.useState(0)


    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y <= scrollLimit){
            setScrolledDown(true)
        } else {
            setScrolledDown(false)
        }
    }, [scrollLimit])
    return (
        <div className={classes.homeWrapper} ref={homeWrapperRef}>
            <div className={classes.banner}>
                <VideoBanner videoURI="/Videos/banner.mp4">
                    <BannerText>
                        <div className={classes.homeBannerText} ref={homeTextRef}>
                            We are a team of researchers, brand planners and designers that
                            develop product ideas and brand strategies through our
                            understanding of people and culture
                        </div>
                        <div
                            className={`${classes.bannerNavigation}`}
                            onClick={() => { scrollToContent() }}
                            ref={bannerNavigationRef}
                        >
                            {!sm &&
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="20" fill="white" />
                                    <g clip-path="url(#clip0)">
                                        <path d="M26.0942 25.0162L25.1043 24.0263L20.6995 28.4311L20.6995 8.88867L19.2996 8.88867L19.2996 28.4311L14.8948 24.0263L13.9049 25.0162L19.9996 31.1109L26.0942 25.0162Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="22.2222" height="22.2222" fill="white" transform="translate(31.1108 8.88867) rotate(90)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            }
                            {sm &&
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="white" />
                                    <g clip-path="url(#clip0)">
                                        <path d="M20.876 20.0132L20.0841 19.2213L16.5602 22.7452L16.5602 7.11121L15.4402 7.11121L15.4402 22.7452L11.9164 19.2213L11.1245 20.0132L16.0002 24.889L20.876 20.0132Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="17.7778" height="17.7778" fill="white" transform="translate(24.8896 7.11121) rotate(90)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            }
                        </div>
                    </BannerText>
                </VideoBanner>
            </div>
            <div className={classes.contentWrapper} ref={contentRef}>
                <div className={classes.projects}>
                    <Grid container spacing={1}>
                        {projects.map(function (p) {
                            return (
                                <Grid item sm={4} xs={12}>
                                    <ProjectCard data={p} key={p.id} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                <div className={`text ${classes.CTALink}`}>
                    <Link to={`/project`}>
                        See More Projects
                    </Link>
                    <span className={classes.CTAArrow}>
                        {!sm &&
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.4177 5.41785L16.3487 6.48693L21.1059 11.2442H0V12.7561H21.1059L16.3487 17.5133L17.4177 18.5824L24 12.0001L17.4177 5.41785Z" fill="#EF4623" />
                            </svg>
                        }
                        {sm &&
                            <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.1266 9.61133L24.523 11.4621L31.6588 19.6977H0V22.3151H31.6588L24.523 30.5506L26.1266 32.4014L36 21.0063L26.1266 9.61133Z" fill="#EF4623" />
                            </svg>
                        }
                    </span>
                </div>
            </div>
            {/* </Collapse> */}
        </div>
    )
}